import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { HomePage } from './home.page'
import { SharedModule } from '../shared/shared.module'
import { CategoryLinkComponent } from './category-link.component'
import { MatIconModule } from '@angular/material'

const COMPONENTS = [HomePage, CategoryLinkComponent]

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, MatIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class HomeModule {}
