import { SVIVoterConfig } from './voter.configuration'

export const environment: SVIVoterConfig = {
  averageAnswersRecommendationId: 'average_answers_2022-schulmuseum_production',
  production: true,
  portierUrl: 'https://4rsxmzoot3.execute-api.eu-central-1.amazonaws.com/production/graphql',
  sharing: {
    pageGrabberUrl: 'http://page-grabber.smartvote.ch/screenshot',
    twitterUsername: 'smartvoteCH'
  },
  emailerUrl: 'https://7degjxh594.execute-api.eu-central-1.amazonaws.com/production/send-email',
  emailerFromAddress: 'smartvote.org<info@smartvote.org>',
  contactFormToAddress: 'info@smartvote.org',
  matomo: {
    url: 'https://analytics.smartvote.ch',
    siteId: 0
  },
  matchingModuleConfiguration: { hasSmartmap: false, showDividingLine: false },
  questionnaireModuleConfiguration: { showWeight: false },
  showPrivacyLayover: false
}
