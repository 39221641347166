import { Component, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core'
import { Observable } from 'apollo-link'
import { Candidate, Question, Voter } from '@smartvote/common'
import { ActivatedRoute, Router } from '@angular/router'
import { switchMap, map } from 'rxjs/operators'
import { Apollo } from 'apollo-angular'
import { CandidateDetailsWithVoterQuery } from '../../__generated__/types'
import { combineLatest } from 'rxjs'
import { VoterIdService } from '../core/voter-id.service'
import { isPlatformBrowser } from '@angular/common'
import { AnswerService } from '@core/answer.service'
import { LocalStorage } from '@core/tokens'
const {
  CandidateDetailsWithVoter,
  CandidateDetails
} = require('graphql-tag/loader!./details.page.graphql')

export interface CandidateDetails {
  candidate: Candidate
  questions: Question[]
  voter: Voter
  matchValue: number
}
@Component({
  selector: 'svi-candidate-details',
  templateUrl: 'candidate-details.page.html',
  styleUrls: ['candidate-details.page.scss']
})
export class CandidateDetailsPage implements AfterViewInit {
  data: Observable<CandidateDetails>
  answers: any = []
  questions: any[] = []
  categories: Set<any>
  tabIndex = 0
  showMySmartspider = true

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    voterIdService: VoterIdService,
    private answerService: AnswerService,
    @Inject(LocalStorage) private _localStorage: Storage,
    private apollo: Apollo,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.answers = this.answerService.getAnswers()
    this.data = combineLatest([route.params, route.queryParams]).pipe(
      switchMap(([params, queryParams]) => {
        this.tabIndex = parseInt(queryParams['tab'], 10) || 0
        if (queryParams.rid) {
          return this._getQueryWithVoter(params.id, queryParams.rid, params.electionId)
        } else {
          this.router.navigate(['home'])
        }
      }),
      map(({ data }) => {
        const { candidate, questionnaire, recommendation } = data as any
        this.questions = questionnaire.questions
        this.questions.forEach((q) => {
          q['candidateAnswer'] = candidate.answers.find((a) => a.questionId === q.id).value
          const myAnswer = recommendation.voter.answers.find((a) => a.questionId === q.id)
          q['myAnswer'] = myAnswer ? myAnswer.value : -9
        })
        this.categories = new Set(this.questions.map((q) => q.category))
        this.categories.forEach((c) => {
          c['questions'] = this.questions.filter((q) => q.categoryId === c.id)
        })
        // Deep copy hack for issue: https://github.com/apollographql/apollo-angular/issues/329
        if (recommendation) {
          return candidate
            ? {
                candidate: {
                  ...candidate,
                  smartspider: { ...candidate.smartspider, options: { fill: '#333333' } }
                },
                questions: [...questionnaire.questions],
                voter: {
                  ...recommendation.voter,
                  smartspider: {
                    ...recommendation.voter.smartspider,
                    options: { fill: '#e63923' }
                  },
                  isMe: voterIdService.getVoterId() === recommendation.voter.id
                },
                matchValue: recommendation.matchings.find((m) => m.responderId === candidate.id)
                  .matchValue
              }
            : {}
        } else {
          return {}
        }
      })
    ) as any
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0)
    }
  }

  onTabChanged(index: number) {
    this._updateQueryParams({ tab: index })
  }

  getValue(responder: any, key: string, defaultValue?: any) {
    if (!responder || !responder.values) {
      return defaultValue
    }
    const result = responder.values.find((entry) => entry.key === key)
    if (!result || !result.value) {
      return defaultValue
    }
    return result.value
  }

  setShowMySmartspider(value: boolean) {
    this.showMySmartspider = value
  }

  resumeQuestionnaire() {
    const lastQuestionVisited = this._localStorage.getItem('lastQuestionVisited')
    if (!lastQuestionVisited) {
      // find first non-answered question
      const firstUnansweredQuestion = this.questions.find(
        (q) => !this.answers.some((a) => a.questionId === q.id)
      )
      this.router.navigate(['questionnaire', 'category', firstUnansweredQuestion.category.id], {
        queryParams: { questionId: firstUnansweredQuestion.id }
      })
    } else {
      this.router.navigateByUrl(lastQuestionVisited)
    }
  }

  private _updateQueryParams(params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }

  private _getQueryWithVoter(candidateId: string, recommendationId: string, electionId) {
    return this.apollo.query<CandidateDetailsWithVoterQuery>({
      query: CandidateDetailsWithVoter,
      variables: { candidateId, electionId, recommendationId }
    })
  }
}
