import { LayoutComponent } from './layout/layout.component';
import { iframeSettingsProvider } from './iframe';
var COMPONENTS = [LayoutComponent];
var ɵ0 = iframeSettingsProvider, ɵ1 = typeof window !== 'undefined' ? window.localStorage : { setItem: function () { }, getItem: function () { } }, ɵ2 = typeof window !== 'undefined' ? window.sessionStorage : { setItem: function () { }, getItem: function () { } };
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0, ɵ1, ɵ2 };
