import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnswerService } from '@core/answer.service';
import { Apollo } from 'apollo-angular';
import { map, switchMap, tap } from 'rxjs/operators';
export var QUESTIONNAIRE_START = 'start';
export var QUESTIONNAIRE_END = 'end';
export var RECOMMENDATION_CREATED_AT = 'recommmendation_created_at';
var query = require('graphql-tag/loader!./questionnaire.query.graphql');
var QuestionnairePage = /** @class */ (function () {
    function QuestionnairePage(config, localStorage, route, router, apollo, answerService) {
        this.config = config;
        this.localStorage = localStorage;
        this.route = route;
        this.router = router;
        this.apollo = apollo;
        this.answerService = answerService;
    }
    QuestionnairePage.prototype.ngOnInit = function () {
        var _this = this;
        this.question = this.route.paramMap.pipe(tap(function (params) {
            _this.categoryId = params.get('categoryId');
        }), switchMap(function () { return _this.route.queryParamMap; }), tap(function (params) {
            _this.questionId = params.get('questionId');
        }), switchMap(function () { return _this._getQuery(); }), map(function (_a) {
            var data = _a.data, loading = _a.loading;
            var questions = data.questionnaire.questions;
            _this.categoryQuestions = questions.filter(function (q) { return q.category.id === _this.categoryId; });
            _this.userAnswers = _this.answerService.getUserAnswersAsArray(questions);
            // redirect if coming without questionId (default go to first question)
            if (!_this.questionId) {
                var firstUnansweredQuestion = _this.categoryQuestions.find(function (cq) { return _this.userAnswers.find(function (ua) { return ua.questionId === cq.id; }).value < -9; });
                _this.router.navigate(['questionnaire', 'category', _this.categoryId], {
                    queryParams: {
                        questionId: firstUnansweredQuestion
                            ? firstUnansweredQuestion.id
                            : _this.categoryQuestions[0].id
                    }
                });
            }
            // progress
            _this.progress =
                (_this.categoryQuestions.filter(function (cq) { return _this.userAnswers.find(function (ua) { return cq.id === ua.questionId; }).value >= -9; }).length /
                    _this.categoryQuestions.length) *
                    100;
            _this.userAnswer = _this.userAnswers.find(function (ua) { return ua.questionId === _this.questionId; });
            // if the current question has not been answered, it should be visited if
            // the user wants to resume the questionnaire
            if (_this.userAnswer && _this.userAnswer.value < -9) {
                _this.localStorage.setItem('lastQuestionVisited', "questionnaire/category/" + _this.categoryId + "?questionId=" + _this.questionId);
            }
            _this.nextQuestion =
                _this.categoryQuestions[_this.categoryQuestions.findIndex(function (ca) { return ca.id === _this.questionId; }) + 1];
            _this.previousQuestion =
                _this.categoryQuestions[_this.categoryQuestions.findIndex(function (ca) { return ca.id === _this.questionId; }) - 1];
            return questions.find(function (q) { return q.id === _this.questionId; });
        }));
    };
    QuestionnairePage.prototype.submitAnswer = function (value) {
        var _this = this;
        this.userAnswer.value = value;
        this.answerService.saveAnswer(this.userAnswer);
        if (this.nextQuestion) {
            setTimeout(function () {
                return _this.router.navigate(['questionnaire', 'category', _this.categoryId], {
                    queryParams: { questionId: _this.nextQuestion.id }
                });
            }, 200);
        }
        else {
            this.localStorage.removeItem('lastQuestionVisited');
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(function () {
                return _this.router.navigate(['questionnaire', 'category', _this.categoryId], {
                    queryParams: { questionId: _this.questionId }
                });
            });
        }
    };
    QuestionnairePage.prototype.navigateToQuestion = function (question) {
        this.router.navigate(['questionnaire', 'category', question.category.id], {
            queryParams: { questionId: question.id }
        });
    };
    QuestionnairePage.prototype.navigateToHome = function () {
        this.router.navigate(['home']);
    };
    QuestionnairePage.prototype._getQuery = function () {
        return this.apollo.query({
            query: query
        });
    };
    return QuestionnairePage;
}());
export { QuestionnairePage };
