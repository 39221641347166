import * as tslib_1 from "tslib";
import { AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { combineLatest } from 'rxjs';
import { VoterIdService } from '../core/voter-id.service';
import { isPlatformBrowser } from '@angular/common';
import { AnswerService } from '@core/answer.service';
var _a = require('graphql-tag/loader!./details.page.graphql'), CandidateDetailsWithVoter = _a.CandidateDetailsWithVoter, CandidateDetails = _a.CandidateDetails;
var CandidateDetailsPage = /** @class */ (function () {
    function CandidateDetailsPage(route, router, voterIdService, answerService, _localStorage, apollo, platformId) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.answerService = answerService;
        this._localStorage = _localStorage;
        this.apollo = apollo;
        this.platformId = platformId;
        this.answers = [];
        this.questions = [];
        this.tabIndex = 0;
        this.showMySmartspider = true;
        this.answers = this.answerService.getAnswers();
        this.data = combineLatest([route.params, route.queryParams]).pipe(switchMap(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.tabIndex = parseInt(queryParams['tab'], 10) || 0;
            if (queryParams.rid) {
                return _this._getQueryWithVoter(params.id, queryParams.rid, params.electionId);
            }
            else {
                _this.router.navigate(['home']);
            }
        }), map(function (_a) {
            var data = _a.data;
            var _b = data, candidate = _b.candidate, questionnaire = _b.questionnaire, recommendation = _b.recommendation;
            _this.questions = questionnaire.questions;
            _this.questions.forEach(function (q) {
                q['candidateAnswer'] = candidate.answers.find(function (a) { return a.questionId === q.id; }).value;
                var myAnswer = recommendation.voter.answers.find(function (a) { return a.questionId === q.id; });
                q['myAnswer'] = myAnswer ? myAnswer.value : -9;
            });
            _this.categories = new Set(_this.questions.map(function (q) { return q.category; }));
            _this.categories.forEach(function (c) {
                c['questions'] = _this.questions.filter(function (q) { return q.categoryId === c.id; });
            });
            // Deep copy hack for issue: https://github.com/apollographql/apollo-angular/issues/329
            if (recommendation) {
                return candidate
                    ? {
                        candidate: tslib_1.__assign({}, candidate, { smartspider: tslib_1.__assign({}, candidate.smartspider, { options: { fill: '#333333' } }) }),
                        questions: questionnaire.questions.slice(),
                        voter: tslib_1.__assign({}, recommendation.voter, { smartspider: tslib_1.__assign({}, recommendation.voter.smartspider, { options: { fill: '#e63923' } }), isMe: voterIdService.getVoterId() === recommendation.voter.id }),
                        matchValue: recommendation.matchings.find(function (m) { return m.responderId === candidate.id; })
                            .matchValue
                    }
                    : {};
            }
            else {
                return {};
            }
        }));
    }
    CandidateDetailsPage.prototype.ngAfterViewInit = function () {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }
    };
    CandidateDetailsPage.prototype.onTabChanged = function (index) {
        this._updateQueryParams({ tab: index });
    };
    CandidateDetailsPage.prototype.getValue = function (responder, key, defaultValue) {
        if (!responder || !responder.values) {
            return defaultValue;
        }
        var result = responder.values.find(function (entry) { return entry.key === key; });
        if (!result || !result.value) {
            return defaultValue;
        }
        return result.value;
    };
    CandidateDetailsPage.prototype.setShowMySmartspider = function (value) {
        this.showMySmartspider = value;
    };
    CandidateDetailsPage.prototype.resumeQuestionnaire = function () {
        var _this = this;
        var lastQuestionVisited = this._localStorage.getItem('lastQuestionVisited');
        if (!lastQuestionVisited) {
            // find first non-answered question
            var firstUnansweredQuestion = this.questions.find(function (q) { return !_this.answers.some(function (a) { return a.questionId === q.id; }); });
            this.router.navigate(['questionnaire', 'category', firstUnansweredQuestion.category.id], {
                queryParams: { questionId: firstUnansweredQuestion.id }
            });
        }
        else {
            this.router.navigateByUrl(lastQuestionVisited);
        }
    };
    CandidateDetailsPage.prototype._updateQueryParams = function (params) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    CandidateDetailsPage.prototype._getQueryWithVoter = function (candidateId, recommendationId, electionId) {
        return this.apollo.query({
            query: CandidateDetailsWithVoter,
            variables: { candidateId: candidateId, electionId: electionId, recommendationId: recommendationId }
        });
    };
    return CandidateDetailsPage;
}());
export { CandidateDetailsPage };
