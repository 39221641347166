<p class="small bold">{{ text }}</p>
<p class="small">{{ infoText }}</p>
<div class="answer">
  <div class="vertical-bar yes"></div>
  <p class="small yes label" i18n="@@yes">Ja</p>
  <div class="vertical-bar ratherYes"></div>
  <p class="small ratherYes label" i18n="@@ratherYes">Eher ja</p>
  <div class="vertical-bar ratherNo"></div>
  <p class="small ratherNo label" i18n="@@ratherNo">Eher nein</p>
  <div class="vertical-bar no"></div>
  <p class="small no label" i18n="@@no">Nein</p>
  <div class="horizontal-bar"></div>
  <mat-icon
    class="candidate"
    [ngClass]="{
      yes: candidateAnswer === 100,
      ratherYes: candidateAnswer === 75,
      ratherNo: candidateAnswer === 25,
      no: candidateAnswer === 0,
      'same-answer': candidateAnswer === myAnswer && candidateAnswer >= 0 && myAnswer >= 0
    }"
    svgIcon="rectangle"
  ></mat-icon>
  <mat-icon
    *ngIf="myAnswer >= 0"
    class="voter"
    [ngClass]="{
      yes: myAnswer === 100,
      ratherYes: myAnswer === 75,
      ratherNo: myAnswer === 25,
      no: myAnswer === 0
    }"
    svgIcon="rectangle"
  ></mat-icon>
</div>
