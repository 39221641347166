import { Component, Input } from '@angular/core'
import { Location } from '@angular/common'

export interface Language {
  labelCode: string
  code: string
  lang: string
}
@Component({
  selector: 'svi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() showLogo: boolean
  @Input() showMenu = true
  @Input() showBack = false

  constructor(private location: Location) {}

  back() {
    this.location.back()
  }
}
