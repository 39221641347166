import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
var GetBarometerData = require('graphql-tag/loader!./barometer.page.graphql').GetBarometerData;
var BarometerPage = /** @class */ (function () {
    function BarometerPage(apollo) {
        var _this = this;
        this.apollo = apollo;
        this.recommendationCount = 0;
        this.barometerData = this.apollo
            .watchQuery({
            query: GetBarometerData,
            variables: {
                recommendationId: environment.averageAnswersRecommendationId,
                offset: 0,
                limit: -1
            },
            pollInterval: 5000
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            var barometerData = tslib_1.__assign({}, data);
            if (!barometerData.recommendation) {
                barometerData.recommendation = {
                    matchings: [],
                    voter: {
                        smartspider: data.smartspider
                    }
                };
            }
            barometerData.recommendation.voter.smartspider['options'] = { fill: '#e63923' };
            if (_this.recommendationCount !== barometerData.statistics.recommendationCount) {
                _this.dotElement.nativeElement.classList.toggle('animate');
                setTimeout(function () {
                    _this.dotElement.nativeElement.classList.toggle('animate');
                }, 200);
            }
            _this.recommendationCount = barometerData.statistics.recommendationCount;
            return barometerData;
        }));
    }
    return BarometerPage;
}());
export { BarometerPage };
