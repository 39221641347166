import { Component, OnInit, Inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AnswerService } from '@core/answer.service'
import { QuestionnaireQuery } from '@graphql_types'
import { Answer, Question } from '@smartvote/common'
import { Apollo } from 'apollo-angular'
import { Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { QUESTIONNAIRE_MODULE_CONFIG } from './questionnaire.module'
import { QuestionnaireModuleConfiguration } from './questionnaire.module.configuration'
import { LocalStorage } from '@core/tokens'

export const QUESTIONNAIRE_START = 'start'
export const QUESTIONNAIRE_END = 'end'
export const RECOMMENDATION_CREATED_AT = 'recommmendation_created_at'

const query = require('graphql-tag/loader!./questionnaire.query.graphql')
@Component({
  selector: 'svi-questionnaire-page',
  templateUrl: 'questionnaire.page.html',
  styleUrls: ['questionnaire.page.scss']
})
export class QuestionnairePage implements OnInit {
  categoryId: string
  questionId: string
  question: Observable<Question>
  categoryQuestions: any[]
  progress: number
  userAnswers: Answer[]
  userAnswer: Answer
  nextQuestion: any
  previousQuestion: any

  constructor(
    @Inject(QUESTIONNAIRE_MODULE_CONFIG) readonly config: QuestionnaireModuleConfiguration,
    @Inject(LocalStorage) private localStorage: Storage,
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private answerService: AnswerService
  ) {}

  ngOnInit() {
    this.question = this.route.paramMap.pipe(
      tap((params) => {
        this.categoryId = params.get('categoryId')
      }),
      switchMap(() => this.route.queryParamMap),
      tap((params) => {
        this.questionId = params.get('questionId')
      }),
      switchMap(() => this._getQuery()),
      map(({ data, loading }) => {
        const questions = data.questionnaire.questions
        this.categoryQuestions = questions.filter((q) => q.category.id === this.categoryId)
        this.userAnswers = this.answerService.getUserAnswersAsArray(questions)
        // redirect if coming without questionId (default go to first question)
        if (!this.questionId) {
          const firstUnansweredQuestion = this.categoryQuestions.find(
            (cq) => this.userAnswers.find((ua) => ua.questionId === cq.id).value < -9
          )
          this.router.navigate(['questionnaire', 'category', this.categoryId], {
            queryParams: {
              questionId: firstUnansweredQuestion
                ? firstUnansweredQuestion.id
                : this.categoryQuestions[0].id
            }
          })
        }
        // progress
        this.progress =
          (this.categoryQuestions.filter(
            (cq) => this.userAnswers.find((ua) => cq.id === ua.questionId).value >= -9
          ).length /
            this.categoryQuestions.length) *
          100
        this.userAnswer = this.userAnswers.find((ua) => ua.questionId === this.questionId)
        // if the current question has not been answered, it should be visited if
        // the user wants to resume the questionnaire
        if (this.userAnswer && this.userAnswer.value < -9) {
          this.localStorage.setItem(
            'lastQuestionVisited',
            `questionnaire/category/${this.categoryId}?questionId=${this.questionId}`
          )
        }

        this.nextQuestion =
          this.categoryQuestions[
            this.categoryQuestions.findIndex((ca) => ca.id === this.questionId) + 1
          ]

        this.previousQuestion =
          this.categoryQuestions[
            this.categoryQuestions.findIndex((ca) => ca.id === this.questionId) - 1
          ]

        return questions.find((q) => q.id === this.questionId)
      })
    ) as any
  }

  submitAnswer(value: number) {
    this.userAnswer.value = value
    this.answerService.saveAnswer(this.userAnswer)
    if (this.nextQuestion) {
      setTimeout(
        () =>
          this.router.navigate(['questionnaire', 'category', this.categoryId], {
            queryParams: { questionId: this.nextQuestion.id }
          }),
        200
      )
    } else {
      this.localStorage.removeItem('lastQuestionVisited')
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['questionnaire', 'category', this.categoryId], {
          queryParams: { questionId: this.questionId }
        })
      )
    }
  }

  navigateToQuestion(question) {
    this.router.navigate(['questionnaire', 'category', question.category.id], {
      queryParams: { questionId: question.id }
    })
  }

  navigateToHome() {
    this.router.navigate(['home'])
  }

  private _getQuery() {
    return this.apollo.query<QuestionnaireQuery>({
      query
    })
  }
}
