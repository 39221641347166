import { Component, Input } from '@angular/core'
import { Smartspider } from '@smartvote/common'

@Component({
  selector: 'svi-print-list-item',
  templateUrl: 'print-list-item.component.html',
  styleUrls: ['print-list-item.component.scss']
})
export class PrintListItemComponent {
  @Input() rank: number
  @Input() matchingValue: number
  @Input() title: string
  @Input() smartspiders: Smartspider[]
}
