import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '../shared/shared.module'

import { UserSurveyPage } from './user-survey.page'
import { MatIconModule } from '@angular/material'

const COMPONENTS = [UserSurveyPage]

@NgModule({
  imports: [CommonModule, SharedModule, MatIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class UserSurveyModule {}
