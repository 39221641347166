import { Router } from '@angular/router';
import { AnswerService } from '../core/answer.service';
import { Apollo } from 'apollo-angular';
var query = require('graphql-tag/loader!./questionnaire-categories.query.graphql');
var HomePage = /** @class */ (function () {
    function HomePage(router, answerService, apollo, localStorage) {
        var _this = this;
        this.router = router;
        this.answerService = answerService;
        this.apollo = apollo;
        this.localStorage = localStorage;
        this.answers = [];
        this.loading = true;
        this.answers = this.answerService.getAnswers();
        this._getQuery()
            .toPromise()
            .then(function (_a) {
            var data = _a.data;
            _this.questions = data.questionnaire.questions;
            var categories = [];
            data.questionnaire.questions.forEach(function (q) {
                var existingCategory = categories.find(function (c) { return c.id === q.category.id; });
                if (existingCategory) {
                    existingCategory.numberOfQuestions = existingCategory.numberOfQuestions + 1;
                }
                else {
                    categories.push({
                        id: q.category.id,
                        name: q.category.name,
                        numberOfQuestions: 1,
                        numberOfAnswers: 0
                    });
                }
            }, []);
            categories.forEach(function (c) {
                c.numberOfAnswers = _this.answers.filter(function (a) { return a.categoryId === c.id; }).length;
            });
            _this.categoriesProgress = categories;
            _this.loading = false;
        });
    }
    HomePage.prototype.resumeQuestionnaire = function () {
        var _this = this;
        var lastQuestionVisited = this.localStorage.getItem('lastQuestionVisited');
        if (!lastQuestionVisited) {
            // find first non-answered question
            var firstUnansweredQuestion = this.questions.find(function (q) { return !_this.answers.some(function (a) { return a.questionId === q.id; }); });
            this.router.navigate(['questionnaire', 'category', firstUnansweredQuestion.category.id], {
                queryParams: { questionId: firstUnansweredQuestion.id }
            });
        }
        else {
            this.router.navigateByUrl(lastQuestionVisited);
        }
    };
    HomePage.prototype.navigateToCategory = function (category) {
        this.router.navigate(['questionnaire', 'category', category.id]);
    };
    HomePage.prototype._getQuery = function () {
        return this.apollo.query({
            query: query
        });
    };
    return HomePage;
}());
export { HomePage };
