/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./core/layout/layout.component.ngfactory";
import * as i2 from "./core/layout/layout.component";
import * as i3 from "./app.component";
import * as i4 from "./core/tracking.service";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/platform-browser";
var styles_AppComponent = [""];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-layout", [], null, null, null, i1.View_LayoutComponent_0, i1.RenderType_LayoutComponent)), i0.ɵdid(1, 49152, null, 0, i2.LayoutComponent, [], null, null)], null, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "svi-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i3.AppComponent, [i4.TrackingService, i5.MatIconRegistry, i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("svi-root", i3.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
