/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./barometer-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./barometer-list-item.component";
var styles_BarometerListItemComponent = [i0.styles];
var RenderType_BarometerListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BarometerListItemComponent, data: {} });
export { RenderType_BarometerListItemComponent as RenderType_BarometerListItemComponent };
export function View_BarometerListItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "rank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "percentage"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " %"])), i1.ɵppd(10, 3), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "progress"]], null, null, null, null, null)), i1.ɵdid(12, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(13, { "width.%": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 13, 0, _co.matchingValue); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rank; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.name; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.matchingValue, ".1-1", "en")); _ck(_v, 9, 0, currVal_2); }); }
export function View_BarometerListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-barometer-list-item", [], null, null, null, View_BarometerListItemComponent_0, RenderType_BarometerListItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.BarometerListItemComponent, [], null, null)], null, null); }
var BarometerListItemComponentNgFactory = i1.ɵccf("svi-barometer-list-item", i3.BarometerListItemComponent, View_BarometerListItemComponent_Host_0, { rank: "rank", matchingValue: "matchingValue", name: "name" }, {}, []);
export { BarometerListItemComponentNgFactory as BarometerListItemComponentNgFactory };
