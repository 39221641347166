/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./barometer.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./barometer-list-item.component.ngfactory";
import * as i3 from "./barometer-list-item.component";
import * as i4 from "../../../node_modules/@smartvote/components/components.ngfactory";
import * as i5 from "@smartvote/components";
import * as i6 from "@angular/common";
import * as i7 from "../shared/loading/loading.component.ngfactory";
import * as i8 from "../shared/loading/loading.component";
import * as i9 from "./barometer.page";
import * as i10 from "apollo-angular";
var styles_BarometerPage = [i0.styles];
var RenderType_BarometerPage = i1.ɵcrt({ encapsulation: 0, styles: styles_BarometerPage, data: {} });
export { RenderType_BarometerPage as RenderType_BarometerPage };
function View_BarometerPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-barometer-list-item", [], null, null, null, i2.View_BarometerListItemComponent_0, i2.RenderType_BarometerListItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.BarometerListItemComponent, [], { rank: [0, "rank"], matchingValue: [1, "matchingValue"], name: [2, "name"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.index + 1); var currVal_1 = _v.context.$implicit.matchValue; var currVal_2 = _v.context.$implicit.responder.firstname; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_BarometerPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "sv-smartspider", [["class", "smartspider"]], null, null, null, i4.View_ɵa_0, i4.RenderType_ɵa)), i1.ɵdid(2, 638976, null, 0, i5.ɵa, [i6.Location, i1.ElementRef], { smartspiders: [0, "smartspiders"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "matching-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarometerPage_2)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.ngIf.recommendation.voter.smartspider); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.ngIf.recommendation.matchings; _ck(_v, 6, 0, currVal_1); }, null); }
function View_BarometerPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-loading", [], null, null, null, i7.View_LoadingComponent_0, i7.RenderType_LoadingComponent)), i1.ɵdid(1, 49152, null, 0, i8.LoadingComponent, [], null, null)], null, null); }
export function View_BarometerPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.DecimalPipe, [i1.LOCALE_ID]), i1.ɵqud(402653184, 1, { dotElement: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BarometerPage_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "horizontal-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "counter-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["dot", 1]], null, 0, "div", [["class", "dot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "visitor-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" Aktuell ", " Teilnehmende "])), i1.ɵppd(10, 3), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_BarometerPage_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.barometerData)); var currVal_1 = i1.ɵnov(_v, 11); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.recommendationCount, ".0-0", "de-CH")); _ck(_v, 9, 0, currVal_2); }); }
export function View_BarometerPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-barometer-page", [], null, null, null, View_BarometerPage_0, RenderType_BarometerPage)), i1.ɵdid(1, 49152, null, 0, i9.BarometerPage, [i10.Apollo], null, null)], null, null); }
var BarometerPageNgFactory = i1.ɵccf("svi-barometer-page", i9.BarometerPage, View_BarometerPage_Host_0, {}, {}, []);
export { BarometerPageNgFactory as BarometerPageNgFactory };
