import { Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { AnswerService } from '../core/answer.service'
import { Apollo } from 'apollo-angular'
import { QuestionnaireCategoriesQuery } from '@graphql_types'
import { LocalStorage } from '../core/tokens'

const query = require('graphql-tag/loader!./questionnaire-categories.query.graphql')

@Component({
  selector: 'svi-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePage {
  categoriesProgress: any
  answers: any = []
  questions: any[]
  loading = true

  constructor(
    private router: Router,
    private answerService: AnswerService,
    private apollo: Apollo,
    @Inject(LocalStorage) private localStorage: Storage
  ) {
    this.answers = this.answerService.getAnswers()
    this._getQuery()
      .toPromise()
      .then(({ data }) => {
        this.questions = data.questionnaire.questions
        const categories = []
        data.questionnaire.questions.forEach((q) => {
          const existingCategory = categories.find((c) => c.id === q.category.id)
          if (existingCategory) {
            existingCategory.numberOfQuestions = existingCategory.numberOfQuestions + 1
          } else {
            categories.push({
              id: q.category.id,
              name: q.category.name,
              numberOfQuestions: 1,
              numberOfAnswers: 0
            })
          }
        }, [])
        categories.forEach((c) => {
          c.numberOfAnswers = this.answers.filter((a) => a.categoryId === c.id).length
        })
        this.categoriesProgress = categories
        this.loading = false
      })
  }

  resumeQuestionnaire() {
    const lastQuestionVisited = this.localStorage.getItem('lastQuestionVisited')
    if (!lastQuestionVisited) {
      // find first non-answered question
      const firstUnansweredQuestion = this.questions.find(
        (q) => !this.answers.some((a) => a.questionId === q.id)
      )
      this.router.navigate(['questionnaire', 'category', firstUnansweredQuestion.category.id], {
        queryParams: { questionId: firstUnansweredQuestion.id }
      })
    } else {
      this.router.navigateByUrl(lastQuestionVisited)
    }
  }

  navigateToCategory(category: any) {
    this.router.navigate(['questionnaire', 'category', category.id])
  }

  private _getQuery() {
    return this.apollo.query<QuestionnaireCategoriesQuery>({
      query
    })
  }
}
