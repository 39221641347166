<div class="content">
  <svi-header [showLogo]="true"></svi-header>
</div>

<ng-container *ngIf="!loading">
  <div class="content content-vpad margin-m">
    <h1 i18n="@@home.title">Erstelle dein bildungspolitisches Profil</h1>
    <p *ngIf="answers && answers.length === 0" i18n="@@home.text">
      Kurzer Erklärtext zur Ausstellung und Nutzung dieser Plattform. Aenean eu leo quam.
      Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec id elit non mi porta gravida
      at eget metus.
    </p>
    <p
      *ngIf="answers && answers.length > 0 && answers.length < questions.length"
      i18n="@@home.textQuestionnaireInProgress"
    >
      Du hast <b>{{answers.length}} von {{questions.length}} Fragen</b> beantwortet. Um ein
      aussagekräftiges Bildungsprofil zu erhalten empfehlen wir dir mit der Umfrage fortzufahren.<br />
      Wenn du dich in der Ausstellung befindest kannst du jetzt zu einem anderen Posten gehen und
      dein Smartphone an den NFC-Tag halten.
    </p>
    <p *ngIf="answers.length === questions.length" i18n="@@home.textQuestionnaireComplete">
      Du hast alle Fragen beantwortet. Du kannst dir jetzt dein Bildungsprofil erstellen lassen.
    </p>
    <div class="button-container">
      <button
        *ngIf="answers && answers.length === 0"
        routerLink="/questionnaire/category/1"
        class="button button-primary icon-animated"
      >
        <ng-container i18n="@@home.startQuestionnaire">Umfrage starten</ng-container>
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </button>
      <button
        *ngIf="answers && answers.length > 0 && answers.length < questions.length"
        (click)="resumeQuestionnaire()"
        class="button button-primary icon-animated"
      >
        <ng-container i18n="@@resumeQuestionnaire">Umfrage fortsetzen</ng-container>
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </button>
      <button
        *ngIf="answers && answers.length > 0"
        routerLink="/user-survey"
        class="button icon-animated"
        [ngClass]="{ 'button-primary': answers.length === questions.length }"
      >
        <ng-container i18n="@@home.showResults">Auswertung anzeigen</ng-container>
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </button>
    </div>
  </div>

  <div class="content content-vpad content-dark">
    <h1 i18n="@@home.categoryTitle">Die acht Einflussfaktoren im Überblick</h1>
    <div class="category-container">
      <svi-category-link
        *ngFor="let c of categoriesProgress; let i = index"
        [rank]="i + 1"
        [title]="c.name"
        [progress]="c.numberOfAnswers / c.numberOfQuestions * 100"
        (click)="navigateToCategory(c)"
      ></svi-category-link>
    </div>
  </div>
</ng-container>
