import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { SmartspiderModule } from '@smartvote/components'
import { BarometerListItemComponent } from './barometer-list-item.component'
import { BarometerPage } from './barometer.page'

const COMPONENTS = [BarometerPage, BarometerListItemComponent]

@NgModule({
  imports: [CommonModule, SharedModule, SmartspiderModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class BarometerModule {}
