import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { LogoComponent } from './logo/logo.component'
import { HeaderComponent } from './header/header.component'

import { TabComponent } from './tab/tab.component'
import { LoadingComponent } from './loading/loading.component'
import { TabGroupComponent } from './tab-group/tab-group.component'
import { ChartLegendItemComponent } from './chart-legend-item/chart-legend-item.component'
import { AccordionItemComponent } from './accordion-item/accordion-item.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { OverlayModule } from '@angular/cdk/overlay'
import { NavbarMenuComponent } from './navbar/navbar-menu/navbar-menu.component'
import { NavbarMenuOverlayComponent } from './navbar/navbar-menu/navbar-menu-overlay/navbar-menu-overlay.component'
import { ListItemComponent } from './list-item/list-item.component'

import { MatIconModule } from '@angular/material'

const COMPONENTS = [
  LogoComponent,
  TabComponent,
  TabGroupComponent,
  ChartLegendItemComponent,
  AccordionItemComponent,
  NavbarMenuComponent,
  NavbarMenuOverlayComponent,
  ListItemComponent,
  LoadingComponent,
  HeaderComponent
]

@NgModule({
  imports: [CommonModule, RouterModule, BrowserAnimationsModule, OverlayModule, MatIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  entryComponents: [NavbarMenuOverlayComponent]
})
export class SharedModule {}
