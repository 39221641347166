<div class="rank">
  <div>
    {{ rank }}
  </div>
</div>
<div class="right">
  <div class="title">
    <p class="bold">{{ name }}</p>
    <div class="percentage">{{ matchingValue | number: '.1-1':'en' }} %</div>
  </div>
  <div class="progress" [ngStyle]="{ 'width.%': matchingValue }"></div>
</div>
