import { Component, OnInit, Input, ElementRef } from '@angular/core'
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations'
import { CdkAccordionItem } from '@angular/cdk/accordion'

/** Time and timing curve for expansion panel animations. */
export const EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)'

@Component({
  selector: 'svi-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('collapsed <=> expanded', animate(EXPANSION_PANEL_ANIMATION_TIMING))
    ]),
    trigger('bodyExpansion', [
      state('collapsed', style({ height: '0px', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('collapsed <=> expanded', animate(EXPANSION_PANEL_ANIMATION_TIMING))
    ])
  ]
})
export class AccordionItemComponent extends CdkAccordionItem {
  @Input() title: string
  @Input() rank: number

  _getExpandedState() {
    return this.expanded ? 'expanded' : 'collapsed'
  }

  /* From angular material */
  _bodyAnimation(event: AnimationEvent) {
    const classList = event.element.classList
    const cssClass = 'expanded'
    const { phaseName, toState } = event

    // Toggle the body's `overflow: hidden` class when closing starts or when expansion ends in
    // order to prevent the cases where switching too early would cause the animation to jump.
    // Note that we do it directly on the DOM element to avoid the slight delay that comes
    // with doing it via change detection.
    if (phaseName === 'done' && toState === 'expanded') {
      classList.add(cssClass)
    } else if (phaseName === 'start' && toState === 'collapsed') {
      classList.remove(cssClass)
    }
  }
}
