import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomePage } from './home/home.page'
import { QuestionnairePage } from './questionnaire/questionnaire.page'
import { UserSurveyPage } from './user-survey/user-survey.page'
import { MatchingPage } from './matching/matching.page'
import { CandidateDetailsPage } from './responder-details/candidate-details.page'
import { BarometerPage } from './barometer/barometer.page'

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomePage },
  { path: 'questionnaire/category/:categoryId', component: QuestionnairePage },
  { path: 'results', component: MatchingPage },
  { path: 'profile/:id/:electionId', component: CandidateDetailsPage },
  { path: 'user-survey', component: UserSurveyPage },
  { path: 'barometer', component: BarometerPage }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
