import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { LayoutComponent } from './layout/layout.component'
import { iframeSettingsProvider, IFRAME_SETTINGS } from './iframe'
import { TransferState } from '@angular/platform-browser'
import { SharedModule } from '../shared/shared.module'
import { LocalStorage, SessionStorage } from './tokens'
import { AnswerService } from './answer.service'
import { VoterIdService } from './voter-id.service'
import { TrackingService } from './tracking.service'

const COMPONENTS = [LayoutComponent]

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    {
      provide: IFRAME_SETTINGS,
      useFactory: iframeSettingsProvider,
      deps: [TransferState]
    },
    {
      provide: LocalStorage,
      useValue: typeof window !== 'undefined' ? window.localStorage : { setItem() {}, getItem() {} }
    },
    {
      provide: SessionStorage,
      useValue:
        typeof window !== 'undefined' ? window.sessionStorage : { setItem() {}, getItem() {} }
    },
    AnswerService,
    VoterIdService,
    TrackingService
  ]
})
export class CoreModule {}
