import { Component, Input } from '@angular/core'

@Component({
  selector: 'svi-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
  @Input() photoUrl: string
  @Input() rank: number
  @Input() matchingValue: number
  @Input() fullWidth: boolean
}
