import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SharedModule } from '../shared/shared.module'

import { CandidateDetailsPage } from './candidate-details.page'
import { SmartspiderModule } from '@smartvote/components'
import { MatIconModule } from '@angular/material'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { AnswerComponent } from './answer.component'

const COMPONENTS = [CandidateDetailsPage, AnswerComponent]

@NgModule({
  imports: [CommonModule, SharedModule, SmartspiderModule, MatIconModule, CdkAccordionModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ResponderDetailsModule {}
