import { Component, Inject } from '@angular/core'
import { NavbarMenuOverlayRemote } from './navbar-menu-overlay-remote'
import { Router } from '@angular/router'
import { LocalStorage } from '@core/tokens'
import { AnswerService } from '@core/answer.service'
import { NavbarQuery } from '@graphql_types'
import { Apollo } from 'apollo-angular'

const query = require('graphql-tag/loader!./navbar.query.graphql')

@Component({
  selector: 'svi-navbar-menu-overlay',
  templateUrl: './navbar-menu-overlay.component.html',
  styleUrls: ['./navbar-menu-overlay.component.scss']
})
export class NavbarMenuOverlayComponent {
  answers: any[]

  constructor(
    public remoteControl: NavbarMenuOverlayRemote,
    private answerService: AnswerService,
    private apollo: Apollo,
    private router: Router,
    @Inject(LocalStorage) private localStorage: Storage
  ) {
    this.answers = this.answerService.getAnswers()
  }

  // Close this overlay via it's remote control
  close() {
    this.remoteControl.close()
  }

  async resumeQuestionnaire() {
    const lastQuestionVisited = this.localStorage.getItem('lastQuestionVisited')
    if (!lastQuestionVisited) {
      // find first non-answered question
      const answers = this.answerService.getAnswers()
      const questions = await this._getQuery()
        .toPromise()
        .then(({ data }) => data.questionnaire.questions)
      const firstUnansweredQuestion = questions.find(
        (q) => !answers.some((a) => a.questionId === q.id)
      )
      if (!firstUnansweredQuestion) {
        this.router.navigate(['questionnaire', 'category', questions[0].category.id], {
          queryParams: { questionId: questions[0].id }
        })
      } else {
        this.router.navigate(['questionnaire', 'category', firstUnansweredQuestion.category.id], {
          queryParams: { questionId: firstUnansweredQuestion.id }
        })
      }
    } else {
      this.router.navigateByUrl(lastQuestionVisited)
    }
    this.close()
  }

  private _getQuery() {
    return this.apollo.query<NavbarQuery>({
      query
    })
  }
}
