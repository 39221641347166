import {
  Component,
  OnInit,
  QueryList,
  ContentChildren,
  Input,
  Output,
  EventEmitter
} from '@angular/core'
import { TabComponent } from '../tab/tab.component'

@Component({
  selector: 'svi-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss']
})
export class TabGroupComponent implements OnInit {
  @ContentChildren(TabComponent) _tabs: QueryList<TabComponent>
  @Input() selectedIndex = 0
  @Output() readonly selectedTabChanged = new EventEmitter<number>()

  constructor() {}

  ngOnInit() {}

  onClick(idx: number) {
    if (idx === this.selectedIndex) {
      return
    }
    this.selectedIndex = idx
    this.selectedTabChanged.emit(idx)
  }
}
