<div class="content">
  <svi-header [showMenu]="false" [showBack]="true"></svi-header>
</div>

<ng-container *ngIf="data | async as data; else loading">
  <div class="content content-vpad margin-m">
    <h1>{{data.candidate.firstname}}</h1>
    <p>
      {{ getValue(data.candidate, 'statement') }}&nbsp;<ng-container
        i18n="@@profile.moreAboutInitiative"
        >Mehr zu dieser Initiative erfährst du
        <a [href]="getValue(data.candidate, 'website')" target="_blank">hier</a>.</ng-container
      >
    </p>
    <p i18n="@@profile.agreement">
      Du stimmst zu {{ data.matchValue | number: '.1-1':'en' }}% mit dieser Initiative überein.
    </p>
  </div>

  <div class="content">
    <svi-tab-group
      class="tabs"
      (selectedTabChanged)="onTabChanged($event)"
      [(selectedIndex)]="tabIndex"
    >
      <svi-tab label="Smartspider" i18n-label="@@profile.smartspiderTabLabel">
        <sv-smartspider
          class="smartspider"
          [smartspiders]="
        showMySmartspider
          ? [data.candidate.smartspider, data.voter.smartspider]
          : [data.candidate.smartspider]
      "
        ></sv-smartspider>
        <div class="legend legend-candidate">
          <mat-icon svgIcon="rectangle"></mat-icon>
          <p class="small">{{data.candidate.firstname}}</p>
        </div>
        <div class="legend legend-voter">
          <mat-icon svgIcon="rectangle"></mat-icon>
          <p class="small" i18n="@@mySmartspider">Mein smartspider</p>
          <p
            *ngIf="showMySmartspider"
            (click)="setShowMySmartspider(false)"
            class="small legend-button"
            i18n="@@hideSmartspider"
          >
            Ausblenden
          </p>
          <p
            *ngIf="!showMySmartspider"
            (click)="setShowMySmartspider(true)"
            class="small legend-button"
            i18n="@@showSmartspider"
          >
            Einblenden
          </p>
        </div>
      </svi-tab>
      <svi-tab label="Fragebogen" i18n-label="@@profile.questionnaireTab">
        <div class="answer-legend">
          <div class="legend legend-voter">
            <mat-icon svgIcon="rectangle"></mat-icon>
            <p class="small" i18n="@@myAnswers">Meine Antworten</p>
          </div>
          <div class="legend legend-candidate">
            <mat-icon svgIcon="rectangle"></mat-icon>
            <p class="small">{{data.candidate.firstname}}</p>
          </div>
        </div>
        <cdk-accordion class="questionnaire" multi="true">
          <svi-accordion-item
            *ngFor="let category of categories; first as isFirstCategory; index as i"
            [title]="category.name"
            [rank]="i + 1"
            [expanded]="isFirstCategory"
          >
            <svi-answer
              *ngFor="let question of category.questions"
              [text]="question.text"
              [infoText]="question.infoText"
              [candidateAnswer]="question.candidateAnswer"
              [myAnswer]="question.myAnswer"
            ></svi-answer>
          </svi-accordion-item>
        </cdk-accordion>
      </svi-tab>
      <svi-tab label="Initiativplakat" i18n-label="@@profile.initiativePoster">
        <div class="poster-container">
          <div
            class="poster"
            [style.background-image]="'url(' + data.candidate.photoUrl + ')'"
          ></div>
        </div>
      </svi-tab>
    </svi-tab-group>
  </div>

  <div
    *ngIf="answers && data.questions && answers.length > 0 && answers.length < data.questions.length"
    class="content content-dark content-vpad margin-m"
  >
    <p i18n="@@resumeQuestionnaireText">
      Du hast <b>{{ answers.length}} von {{ data.questions.length }}</b> Fragen ausgefüllt. Um ein
      aussagekräftiges Smartspider zu erhalten, empfehlen wir dir, mit der Umfrage fortzufahren.
    </p>
    <button
      (click)="resumeQuestionnaire()"
      class="button button-primary icon-animated button-resume"
    >
      <ng-container i18n="@@resumeQuestionnaire">Umfrage fortsetzen</ng-container>
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </button>
  </div>
</ng-container>

<ng-template #loading>
  <svi-loading></svi-loading>
</ng-template>
