import { OverlayRef } from '@angular/cdk/overlay'
import { ElementRef } from '@angular/core'

export class NavbarMenuOverlayRemote {
  constructor(private overlayRef: OverlayRef, private menuButton: ElementRef) {}

  // Toggle animation and then close the overlay that is connected to this remote
  close(): void {
    this.menuButton.nativeElement.classList.toggle('transform-button')
    this.overlayRef.dispose()
  }
}
