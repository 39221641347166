/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accordion-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "./accordion-item.component";
import * as i5 from "@angular/cdk/accordion";
import * as i6 from "@angular/cdk/collections";
var styles_AccordionItemComponent = [i0.styles];
var RenderType_AccordionItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccordionItemComponent, data: { "animation": [{ type: 7, name: "indicatorRotate", definitions: [{ type: 0, name: "collapsed", styles: { type: 6, styles: { transform: "rotate(0deg)" }, offset: null }, options: undefined }, { type: 0, name: "expanded", styles: { type: 6, styles: { transform: "rotate(180deg)" }, offset: null }, options: undefined }, { type: 1, expr: "collapsed <=> expanded", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(0.4,0.0,0.2,1)" }, options: null }], options: {} }, { type: 7, name: "bodyExpansion", definitions: [{ type: 0, name: "collapsed", styles: { type: 6, styles: { height: "0px", visibility: "hidden" }, offset: null }, options: undefined }, { type: 0, name: "expanded", styles: { type: 6, styles: { height: "*", visibility: "visible" }, offset: null }, options: undefined }, { type: 1, expr: "collapsed <=> expanded", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(0.4,0.0,0.2,1)" }, options: null }], options: {} }] } });
export { RenderType_AccordionItemComponent as RenderType_AccordionItemComponent };
export function View_AccordionItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "rank"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "chevron"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "chevron-down"]], [[24, "@indicatorRotate", 0], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "accordion-content"]], [[24, "@bodyExpansion", 0]], [[null, "@bodyExpansion.done"], [null, "@bodyExpansion.start"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@bodyExpansion.done" === en)) {
        var pd_0 = (_co._bodyAnimation($event) !== false);
        ad = (pd_0 && ad);
    } if (("@bodyExpansion.start" === en)) {
        var pd_1 = (_co._bodyAnimation($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var currVal_5 = "chevron-down"; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rank; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co._getExpandedState(); var currVal_3 = i1.ɵnov(_v, 9).inline; var currVal_4 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = _co._getExpandedState(); _ck(_v, 10, 0, currVal_6); }); }
export function View_AccordionItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-accordion-item", [], null, null, null, View_AccordionItemComponent_0, RenderType_AccordionItemComponent)), i1.ɵdid(1, 180224, null, 0, i4.AccordionItemComponent, [[3, i5.CdkAccordion], i1.ChangeDetectorRef, i6.UniqueSelectionDispatcher], null, null)], null, null); }
var AccordionItemComponentNgFactory = i1.ɵccf("svi-accordion-item", i4.AccordionItemComponent, View_AccordionItemComponent_Host_0, { expanded: "expanded", disabled: "disabled", title: "title", rank: "rank" }, { closed: "closed", opened: "opened", destroyed: "destroyed", expandedChange: "expandedChange" }, ["*"]);
export { AccordionItemComponentNgFactory as AccordionItemComponentNgFactory };
