<div class="host" [ngClass]="{ 'full-width': fullWidth, 'half-width': !fullWidth }">
  <div
    class="image"
    [style.background-image]="'url(' + photoUrl + ')'"
    [ngClass]="{ 'full-width': fullWidth, 'half-width': !fullWidth }"
  ></div>
  <div class="container">
    <div class="rank">{{ rank }}.</div>
    <div class="percentage">
      <p class="bold match-value">{{ matchingValue | number: '.1-1':'en' }}%</p>
      <div class="chevron">
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </div>
    </div>
  </div>
  <div class="progress" [ngStyle]="{ 'width.%': matchingValue }"></div>
</div>
