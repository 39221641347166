import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'svi-category-link',
  template: `
    <div class="rank">
      <div>
        {{ rank }}
      </div>
    </div>
    <div class="right">
      <div class="title">
        <p class="bold">{{ title }}</p>
        <div class="chevron">
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </div>
      </div>
      <div class="progress" [ngStyle]="{ 'width.%': progress }"></div>
    </div>
  `,
  styleUrls: ['category-link.component.scss']
})
export class CategoryLinkComponent {
  @Input() rank: number
  @Input() title: string
  @Input() progress: number
}
