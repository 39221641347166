import { Component, Input } from '@angular/core'

@Component({
  selector: 'svi-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['answer.component.scss']
})
export class AnswerComponent {
  @Input() text: string
  @Input() infoText: string
  @Input() myAnswer: number
  @Input() candidateAnswer: number
}
