import * as tslib_1 from "tslib";
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { AnswerService } from '@core/answer.service';
import { VoterIdService } from '@core/voter-id.service';
import { USER_SURVEY_KEY } from '../user-survey/user-survey.page';
import { IframeSettings } from '@core/iframe';
import { QUESTIONNAIRE_START, QUESTIONNAIRE_END, RECOMMENDATION_CREATED_AT } from 'app/questionnaire/questionnaire.page';
import { environment } from '../../environments/environment';
var _a = require('graphql-tag/loader!./matching.page.graphql'), CreateRecommendation = _a.CreateRecommendation, GetRecommendation = _a.GetRecommendation, GetElections = _a.GetElections, GetQuestionnaire = _a.GetQuestionnaire;
var FilterGroupState = /** @class */ (function () {
    function FilterGroupState() {
        this.election = '';
        this.district = '';
        this.responderType = 'Candidate';
    }
    return FilterGroupState;
}());
function stateIsValid(state) {
    return state.election && state.district && state.responderType;
}
function stateIsEqual(a, b) {
    return (a.election === b.election && a.district === b.district && a.responderType === b.responderType);
}
var MatchingPage = /** @class */ (function () {
    function MatchingPage(apollo, router, route, answerService, voterIdService, _localStorage, localeId, config, iframeSettings) {
        var _this = this;
        this.apollo = apollo;
        this.router = router;
        this.route = route;
        this.answerService = answerService;
        this.voterIdService = voterIdService;
        this._localStorage = _localStorage;
        this.localeId = localeId;
        this.config = config;
        this.iframeSettings = iframeSettings;
        this.tabIndex = 0;
        this.answers = [];
        this.questions = [];
        this.showAverageSmartspider = true;
        this.loadingRecommendation = true;
        this._filterGroupState = new FilterGroupState();
        this.answers = this.answerService.getAnswers();
        this.recommendation = combineLatest([
            this.route.queryParams,
            this._getElections(),
            this._getQuestionnaire()
        ]).pipe(switchMap(function (_a) {
            var params = _a[0], elections = _a[1], questionnaire = _a[2];
            var filterGroupState = {
                election: elections[0].id,
                district: elections[0].districts[0].id,
                responderType: 'Candidate'
            };
            _this.questions = questionnaire.questions;
            _this._elections = elections;
            _this.tabIndex = parseInt(params['tab'], 10) || 0;
            var recommendationId = params['rid'];
            _this.loadingRecommendation = true;
            if (_this._doCreateRecommendation(filterGroupState)) {
                return _this.createRecommendation(filterGroupState);
            }
            else if (_this._doGetRecommendation(recommendationId)) {
                return _this._getRecommendation(recommendationId, 0, -1);
            }
            else if (stateIsValid(filterGroupState)) {
                return of(_this._recommendation);
            }
            else {
                _this._updateQueryParams({ rid: null });
                return of(null);
            }
        }), tap(function (recommendation) {
            _this.loadingRecommendation = false;
            if (!recommendation) {
                return;
            }
            recommendation.matchings = recommendation.matchings.map(function (matching) { return (tslib_1.__assign({}, matching, _this._getListItemLabels(matching))); });
            recommendation.matchings.forEach(function (matching) {
                matching.responder.smartspider['options'] = { fill: '#333333' };
            });
            recommendation.voter.smartspider['options'] = { fill: '#e63923' };
            if (!_this._recommendation || _this._recommendation.id !== recommendation.id) {
                // Keep last state before emiting to avoid infite loop
                var districts = _this._elections.filter(function (e) { return e.id === recommendation.options.electionId; })[0].districts;
                var districtGroupId = districts
                    .filter(function (d) { return d.id === recommendation.options.districtId; })
                    .map(function (d) { return d.groupId; })[0];
                // Input districtGroupId manually, since it is not in recommendation.options
                _this._filterGroupState = _this._getFilterGroupState(tslib_1.__assign({}, recommendation.options, { districtGroupId: districtGroupId }));
                _this._recommendation = recommendation;
                _this._updateQueryParams({ rid: recommendation.id });
            }
            _this._localStorage.setItem('recommendationId', recommendation.id);
        }));
        this.averageRecommendation = this.apollo
            .watchQuery({
            query: GetRecommendation,
            variables: {
                recommendationId: environment.averageAnswersRecommendationId,
                offset: 0,
                limit: -1
            },
            pollInterval: 5000
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            var recommendation = tslib_1.__assign({}, data.recommendation);
            if (recommendation) {
                recommendation.voter.smartspider['options'] = { fill: '#333333' };
            }
            return recommendation;
        }));
    }
    MatchingPage.prototype._updateQueryParams = function (params) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });
    };
    MatchingPage.prototype._getListItemLabels = function (matching) {
        var candidate = matching.responder;
        return { title: candidate.firstname, description: '', matchingValue: matching.matchValue };
    };
    MatchingPage.prototype._getFilterGroupState = function (options) {
        return {
            election: options.electionId,
            district: options.districtId,
            districtGroup: options.districtGroupId,
            responderType: options.responderType
        };
    };
    MatchingPage.prototype._getElections = function () {
        return this.apollo
            .query({
            query: GetElections
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.elections;
        }));
    };
    MatchingPage.prototype._getQuestionnaire = function () {
        return this.apollo
            .query({
            query: GetQuestionnaire
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.questionnaire;
        }));
    };
    MatchingPage.prototype._getRecommendation = function (recommendationId, offset, limit) {
        return this.apollo
            .query({
            query: GetRecommendation,
            variables: {
                recommendationId: recommendationId,
                offset: offset,
                limit: limit
            }
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return (tslib_1.__assign({}, data.recommendation));
        }));
    };
    MatchingPage.prototype._doCreateRecommendation = function (filterGroupState) {
        if (stateIsEqual(filterGroupState, this._filterGroupState) || !stateIsValid(filterGroupState)) {
            return false;
        }
        else {
            return true;
        }
    };
    MatchingPage.prototype._doGetRecommendation = function (rid) {
        return rid && !this._recommendation;
    };
    MatchingPage.prototype.createRecommendation = function (filterGroupState) {
        this._localStorage.setItem(RECOMMENDATION_CREATED_AT, new Date().getTime().toString());
        var answers = this.answerService
            .getAnswers()
            .map(function (a) { return ({ questionId: a.questionId, value: a.value, weight: a.weight }); });
        var surveyData = this._localStorage.getItem(USER_SURVEY_KEY) || '';
        var start = this._localStorage.getItem(QUESTIONNAIRE_START) || '';
        var end = this._localStorage.getItem(QUESTIONNAIRE_END) || '';
        var options = {
            responderType: filterGroupState.responderType,
            electionId: filterGroupState.election,
            districtId: filterGroupState.district,
            voterId: this.voterIdService.getVoterId(),
            answers: answers,
            surveyData: surveyData,
            start: start,
            end: end
        };
        if (this.iframeSettings.enabled) {
            options.inlineFrameId = this.iframeSettings.id;
        }
        return this.apollo
            .mutate({
            mutation: CreateRecommendation,
            variables: {
                options: options
            }
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return (tslib_1.__assign({}, data.createRecommendation));
        }));
    };
    MatchingPage.prototype.goToUserSurvey = function () {
        this.router.navigate(['user-survey'], { queryParams: { fwd: true } });
    };
    MatchingPage.prototype.onMatchingSelected = function (matching) {
        if (matching.responder) {
            this.router.navigate(['profile', matching.responder.id, matching.responder.district.electionId], { queryParamsHandling: 'preserve' });
        }
    };
    MatchingPage.prototype.resumeQuestionnaire = function () {
        var _this = this;
        var lastQuestionVisited = this._localStorage.getItem('lastQuestionVisited');
        if (!lastQuestionVisited) {
            // find first non-answered question
            var firstUnansweredQuestion = this.questions.find(function (q) { return !_this.answers.some(function (a) { return a.questionId === q.id; }); });
            this.router.navigate(['questionnaire', 'category', firstUnansweredQuestion.category.id], {
                queryParams: { questionId: firstUnansweredQuestion.id }
            });
        }
        else {
            this.router.navigateByUrl(lastQuestionVisited);
        }
    };
    MatchingPage.prototype.setShowAverageSmartspider = function (value) {
        this.showAverageSmartspider = value;
    };
    MatchingPage.prototype.print = function () {
        window.print();
    };
    return MatchingPage;
}());
export { MatchingPage };
export function getValue(responder, key) {
    if (!responder) {
        return null;
    }
    var result = responder.values.find(function (entry) { return entry.key === key; });
    return result ? result.value : null;
}
