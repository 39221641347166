import * as tslib_1 from "tslib";
import { NavbarMenuOverlayRemote } from './navbar-menu-overlay-remote';
import { Router } from '@angular/router';
import { AnswerService } from '@core/answer.service';
import { Apollo } from 'apollo-angular';
var query = require('graphql-tag/loader!./navbar.query.graphql');
var NavbarMenuOverlayComponent = /** @class */ (function () {
    function NavbarMenuOverlayComponent(remoteControl, answerService, apollo, router, localStorage) {
        this.remoteControl = remoteControl;
        this.answerService = answerService;
        this.apollo = apollo;
        this.router = router;
        this.localStorage = localStorage;
        this.answers = this.answerService.getAnswers();
    }
    // Close this overlay via it's remote control
    NavbarMenuOverlayComponent.prototype.close = function () {
        this.remoteControl.close();
    };
    NavbarMenuOverlayComponent.prototype.resumeQuestionnaire = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lastQuestionVisited, answers_1, questions, firstUnansweredQuestion;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lastQuestionVisited = this.localStorage.getItem('lastQuestionVisited');
                        if (!!lastQuestionVisited) return [3 /*break*/, 2];
                        answers_1 = this.answerService.getAnswers();
                        return [4 /*yield*/, this._getQuery()
                                .toPromise()
                                .then(function (_a) {
                                var data = _a.data;
                                return data.questionnaire.questions;
                            })];
                    case 1:
                        questions = _a.sent();
                        firstUnansweredQuestion = questions.find(function (q) { return !answers_1.some(function (a) { return a.questionId === q.id; }); });
                        if (!firstUnansweredQuestion) {
                            this.router.navigate(['questionnaire', 'category', questions[0].category.id], {
                                queryParams: { questionId: questions[0].id }
                            });
                        }
                        else {
                            this.router.navigate(['questionnaire', 'category', firstUnansweredQuestion.category.id], {
                                queryParams: { questionId: firstUnansweredQuestion.id }
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.router.navigateByUrl(lastQuestionVisited);
                        _a.label = 3;
                    case 3:
                        this.close();
                        return [2 /*return*/];
                }
            });
        });
    };
    NavbarMenuOverlayComponent.prototype._getQuery = function () {
        return this.apollo.query({
            query: query
        });
    };
    return NavbarMenuOverlayComponent;
}());
export { NavbarMenuOverlayComponent };
