<div class="header" (click)="toggle()">
  <div class="rank">{{ rank }}</div>
  <div class="right">
    <div class="title">
      <p class="bold">{{ title }}</p>
      <div class="chevron">
        <mat-icon svgIcon="chevron-down" [@indicatorRotate]="_getExpandedState()"></mat-icon>
      </div>
    </div>
  </div>

  <!-- <i class="fa fa-angle-right" [@indicatorRotate]="_getExpandedState()"></i>
  {{ title }} -->
</div>
<div
  class="accordion-content"
  [@bodyExpansion]="_getExpandedState()"
  (@bodyExpansion.done)="_bodyAnimation($event)"
  (@bodyExpansion.start)="_bodyAnimation($event)"
>
  <ng-content></ng-content>
</div>
