import { Component, Inject, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { LocalStorage } from '../core/tokens'
import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Subscription } from 'rxjs'

export const USER_SURVEY_KEY = 'user-survey'

@Component({
  selector: 'svi-user-survey',
  templateUrl: 'user-survey.page.html',
  styleUrls: ['../questionnaire/questionnaire.page.scss', 'user-survey.page.scss']
})
export class UserSurveyPage implements OnDestroy {
  userSurveyAnswers: any
  progress: number
  paramSub: Subscription
  currentQuestion: number
  currentAnswer: number
  nextQuestion: number
  previousQuestion: number

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LocalStorage) private localStorage: Storage
  ) {
    if (coerceBooleanProperty(localStorage.getItem('user-survey-shown'))) {
      this.continue(true)
      return
    }
    this.paramSub = this.route.queryParamMap.subscribe((paramMap) => {
      this.currentQuestion = parseInt(paramMap.get('questionId'), 10)
      if (!paramMap.has('questionId')) {
        this.router.navigate(['user-survey'], { queryParams: { questionId: 0 } })
        return
      }

      this.userSurveyAnswers = this.localStorage.getItem(USER_SURVEY_KEY)
        ? JSON.parse(this.localStorage.getItem(USER_SURVEY_KEY))
        : { age: -10, gender: -10, education: -10, schoolRelation: -10, ph: -10 }
      this.currentAnswer =
        this.userSurveyAnswers[Object.keys(this.userSurveyAnswers)[this.currentQuestion]]
      this.progress =
        (Object.keys(this.userSurveyAnswers)
          .map((k) => this.userSurveyAnswers[k])
          .filter((v) => v > -10).length /
          Object.keys(this.userSurveyAnswers).length) *
        100
      this.nextQuestion =
        Object.keys(this.userSurveyAnswers).length > this.currentQuestion + 1
          ? this.currentQuestion + 1
          : null
      this.previousQuestion = this.currentQuestion > 0 ? this.currentQuestion - 1 : null
    })
  }
  ngOnDestroy(): void {
    if (this.paramSub) {
      this.paramSub.unsubscribe()
    }
  }

  continue(replaceUrl: boolean = false) {
    localStorage.setItem('user-survey-shown', 'true')
    this.router.navigate(['results'], { replaceUrl })
  }

  submitAnswer(valueObject) {
    this.userSurveyAnswers = { ...this.userSurveyAnswers, ...valueObject }
    this.localStorage.setItem(USER_SURVEY_KEY, JSON.stringify(this.userSurveyAnswers))
    if (
      this.nextQuestion &&
      this.nextQuestion === 4 &&
      this.userSurveyAnswers.schoolRelation !== 3 &&
      this.userSurveyAnswers.schoolRelation !== 4
    ) {
      setTimeout(() => this.continue(), 200)
    } else if (this.nextQuestion) {
      setTimeout(
        () =>
          this.router.navigate(['user-survey'], {
            queryParams: { questionId: this.nextQuestion }
          }),
        200
      )
    } else {
      setTimeout(() => this.continue(), 200)
    }
  }

  navigateToQuestion(questionId) {
    this.router.navigate(['user-survey'], {
      queryParams: { questionId }
    })
  }
}
