/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./category-link.component";
var styles_CategoryLinkComponent = [i0.styles];
var RenderType_CategoryLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoryLinkComponent, data: {} });
export { RenderType_CategoryLinkComponent as RenderType_CategoryLinkComponent };
export function View_CategoryLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "rank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "chevron"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "chevron-right"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "progress"]], null, null, null, null, null)), i1.ɵdid(11, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(12, { "width.%": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_4 = "chevron-right"; _ck(_v, 9, 0, currVal_4); var currVal_5 = _ck(_v, 12, 0, _co.progress); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rank; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9).inline; var currVal_3 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_2, currVal_3); }); }
export function View_CategoryLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "svi-category-link", [], null, null, null, View_CategoryLinkComponent_0, RenderType_CategoryLinkComponent)), i1.ɵdid(1, 49152, null, 0, i5.CategoryLinkComponent, [], null, null)], null, null); }
var CategoryLinkComponentNgFactory = i1.ɵccf("svi-category-link", i5.CategoryLinkComponent, View_CategoryLinkComponent_Host_0, { rank: "rank", title: "title", progress: "progress" }, {}, []);
export { CategoryLinkComponentNgFactory as CategoryLinkComponentNgFactory };
