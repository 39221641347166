import { Component, ElementRef, ViewChild } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Observable } from 'rxjs'
import { GetBarometerDataQuery } from '@graphql_types'
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators'
const { GetBarometerData } = require('graphql-tag/loader!./barometer.page.graphql')

@Component({
  selector: 'svi-barometer-page',
  templateUrl: 'barometer.page.html',
  styleUrls: ['barometer.page.scss']
})
export class BarometerPage {
  barometerData: Observable<any>
  recommendationCount = 0
  @ViewChild('dot') dotElement: ElementRef
  constructor(private apollo: Apollo) {
    this.barometerData = this.apollo
      .watchQuery<GetBarometerDataQuery>({
        query: GetBarometerData,
        variables: {
          recommendationId: environment.averageAnswersRecommendationId,
          offset: 0,
          limit: -1
        },
        pollInterval: 5000
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const barometerData = { ...data } as any
          if (!barometerData.recommendation) {
            barometerData.recommendation = {
              matchings: [],
              voter: {
                smartspider: data.smartspider
              }
            }
          }
          barometerData.recommendation.voter.smartspider['options'] = { fill: '#e63923' }
          if (this.recommendationCount !== barometerData.statistics.recommendationCount) {
            this.dotElement.nativeElement.classList.toggle('animate')
            setTimeout(() => {
              this.dotElement.nativeElement.classList.toggle('animate')
            }, 200)
          }
          this.recommendationCount = barometerData.statistics.recommendationCount
          return barometerData
        })
      )
  }
}
