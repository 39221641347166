<div class="svi-tab-group-header">
  <div
    class="tab-label"
    *ngFor="let tab of _tabs; let i = index"
    [class.active]="i === selectedIndex"
    (click)="onClick(i)"
  >
    {{ tab.label }}
  </div>
</div>

<div
  class="svi-tab-content"
  *ngFor="let tab of _tabs; let i = index"
  [class.active]="i === selectedIndex"
>
  <ng-container *ngTemplateOutlet="tab.content"></ng-container>
</div>
