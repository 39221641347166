import { Component, Input } from '@angular/core'

@Component({
  selector: 'svi-barometer-list-item',
  templateUrl: './barometer-list-item.component.html',
  styleUrls: ['./barometer-list-item.component.scss']
})
export class BarometerListItemComponent {
  @Input() rank: number
  @Input() matchingValue: number
  @Input() name: string
}
