import { Component } from '@angular/core'

@Component({
  selector: 'svi-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  constructor() {}
}
