<div class="content cross-container">
  <mat-icon svgIcon="cross" (click)="navigateToHome()" class="cross-icon"></mat-icon>
</div>
<div class="progress-container">
  <div class="progress-bar" [ngStyle]="{ 'width.%': progress }"></div>
</div>

<div class="content content-vpad margin-m" *ngIf="question | async as question; else loading">
  <div class="margin-s">
    <h2><span class="h2-highlight">&nbsp;{{question.category.name}}&nbsp;</span></h2>
    <h1>{{question.text}}</h1>
    <p>{{question.infoText}}</p>
  </div>
  <div class="answer-buttons">
    <button
      (click)="submitAnswer(100)"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userAnswer.value === 100 }"
    >
      <ng-container i18n="@@yes">Ja</ng-container>
      <mat-icon class="icon-small" *ngIf="userAnswer.value === 100" svgIcon="checkmark"></mat-icon>
    </button>
    <button
      (click)="submitAnswer(75)"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userAnswer.value === 75 }"
    >
      <ng-container i18n="@@ratherYes">Eher ja</ng-container>
      <mat-icon class="icon-small" *ngIf="userAnswer.value === 75" svgIcon="checkmark"></mat-icon>
    </button>
    <button
      (click)="submitAnswer(25)"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userAnswer.value === 25 }"
    >
      <ng-container i18n="@@ratherNo">Eher nein</ng-container>
      <mat-icon class="icon-small" *ngIf="userAnswer.value === 25" svgIcon="checkmark"></mat-icon>
    </button>
    <button
      (click)="submitAnswer(0)"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userAnswer.value === 0 }"
    >
      <ng-container i18n="@@no">Nein</ng-container>
      <mat-icon class="icon-small" *ngIf="userAnswer.value === 0" svgIcon="checkmark"></mat-icon>
    </button>
    <button
      (click)="submitAnswer(-9)"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userAnswer.value === -9 }"
    >
      <ng-container i18n="@@noAnswer">Keine Antwort</ng-container>
      <mat-icon class="icon-small" *ngIf="userAnswer.value === -9" svgIcon="checkmark"></mat-icon>
    </button>
  </div>

  <div class="action-buttons">
    <button
      *ngIf="!nextQuestion && this.userAnswer.value >= -9"
      class="button button-primary button-small icon-animated"
      (click)="navigateToHome()"
    >
      <ng-container i18n="@@complete">Abschliessen</ng-container>
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </button>
  </div>
</div>

<div class="sticky-navigation">
  <button
    [disabled]="!previousQuestion"
    (click)="navigateToQuestion(previousQuestion)"
    class="button-nav"
  >
    <mat-icon svgIcon="chevron-up"></mat-icon>
  </button>
  <button
    [disabled]="!nextQuestion || !userAnswer || userAnswer.value < -9"
    (click)="navigateToQuestion(nextQuestion)"
    class="button-nav"
  >
    <mat-icon svgIcon="chevron-down"></mat-icon>
  </button>
</div>

<ng-template #loading>
  <svi-loading></svi-loading>
</ng-template>
