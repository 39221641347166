import { CdkAccordionModule } from '@angular/cdk/accordion'
import { CommonModule } from '@angular/common'
import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { QuestionnairePage } from './questionnaire.page'
import { QuestionnaireModuleConfiguration } from './questionnaire.module.configuration'
import { MatIconModule } from '@angular/material'

export const QUESTIONNAIRE_MODULE_CONFIG = new InjectionToken<QuestionnaireModuleConfiguration>(
  'QuestionnaireModuleConfiguration'
)

const COMPONENTS = [QuestionnairePage]

@NgModule({
  imports: [CommonModule, SharedModule, CdkAccordionModule, MatIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class QuestionnaireModule {
  public static forRoot(config: QuestionnaireModuleConfiguration): ModuleWithProviders {
    return {
      ngModule: QuestionnaireModule,
      providers: [{ provide: QUESTIONNAIRE_MODULE_CONFIG, useValue: config }]
    }
  }
}
