<div class="content cross-container">
  <mat-icon svgIcon="cross" (click)="continue()" class="cross-icon"></mat-icon>
</div>
<div class="progress-container">
  <div class="progress-bar" [ngStyle]="{ 'width.%': progress }"></div>
</div>

<!-- Age Question -->
<div class="content content-vpad margin-m" *ngIf="currentQuestion === 0">
  <div class="margin-s">
    <h2>
      <span class="h2-highlight"
        >&nbsp;<ng-container i18n="@@userSurvey.tellUsWhoYouAre"
          >Sagst du uns, wer du bist?</ng-container
        >&nbsp;</span
      >
    </h2>
    <h1 i18n="@@userSurvey.question1-age">Wie alt bist du?</h1>
    <p i18n="@@privacyDisclaimer">Disclaimer Text</p>
  </div>
  <div class="answer-buttons">
    <button
      (click)="submitAnswer({age: 1})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.age === 1 }"
    >
      <ng-container i18n="@@userSurvey.question1-age-option1">Unter 18 Jahren</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.age === 1"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({age: 2})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.age === 2 }"
    >
      <ng-container i18n="@@userSurvey.question1-age-option2">19 bis 34 Jahre</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.age === 2"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({age: 3})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.age === 3 }"
    >
      <ng-container i18n="@@userSurvey.question1-age-option3">35 bis 50 Jahre</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.age === 3"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({age: 4})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.age === 4 }"
    >
      <ng-container i18n="@@userSurvey.question1-age-option4">51 bis 66 Jahre</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.age === 4"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({age: 5})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.age === 5 }"
    >
      <ng-container i18n="@@userSurvey.question1-age-option5">Über 66 Jahre</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.age === 5"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({age: -9})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.age === -9 }"
    >
      <ng-container i18n="@@noAnswer">Keine Antwort</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.age === -9"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
  </div>
</div>

<!-- Gender Question -->
<div class="content content-vpad margin-m" *ngIf="currentQuestion === 1">
  <div class="margin-s">
    <h2>
      <span class="h2-highlight"
        >&nbsp;<ng-container i18n="@@userSurvey.tellUsWhoYouAre"
          >Sagst du uns, wer du bist?</ng-container
        >&nbsp;</span
      >
    </h2>
    <h1 i18n="@@userSurvey.question2-gender">Mit welchem Geschlecht identifizierst du dich?</h1>
  </div>
  <div class="answer-buttons">
    <button
      (click)="submitAnswer({gender: 1})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.gender === 1 }"
    >
      <ng-container i18n="@@userSurvey.question2-gender-option1">weiblich</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.gender === 1"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({gender: 2})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.gender === 2 }"
    >
      <ng-container i18n="@@userSurvey.question2-gender-option2">männlich</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.gender === 2"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({gender: 3})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.gender === 3 }"
    >
      <ng-container i18n="@@userSurvey.question2-gender-option3">divers</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.gender === 3"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({gender: -9})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.gender === -9 }"
    >
      <ng-container i18n="@@noAnswer">Keine Antwort</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.gender === -9"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
  </div>
</div>

<!-- Education Question -->
<div class="content content-vpad margin-m" *ngIf="currentQuestion === 2">
  <div class="margin-s">
    <h2>
      <span class="h2-highlight"
        >&nbsp;<ng-container i18n="@@userSurvey.tellUsWhoYouAre"
          >Sagst du uns, wer du bist?</ng-container
        >&nbsp;</span
      >
    </h2>
    <h1 i18n="@@userSurvey.question3-education">Was ist dein höchster Schulabschluss?</h1>
  </div>
  <div class="answer-buttons">
    <button
      (click)="submitAnswer({education: 1})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.education === 1 }"
    >
      <ng-container i18n="@@userSurvey.question3-education-option1">Sekundarstufe I</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.education === 1"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({education: 2})"
      class="button button-answer icon-right extra-large"
      [ngClass]="{ 'selected': userSurveyAnswers.education === 2 }"
    >
      <div>
        <div i18n="@@userSurvey.question3-education-option2">Sekundarstufe II</div>
        <p class="small" i18n="@@userSurvey.question3-education-option2-examples">
          z. B. Berufliche Grundbildung, Gymnasium, Fachmittelschule
        </p>
      </div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.education === 2"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({education: 3})"
      class="button button-answer icon-right extra-large"
      [ngClass]="{ 'selected': userSurveyAnswers.education === 3 }"
    >
      <div>
        <div i18n="@@userSurvey.question3-education-option3">Tertiärstufe</div>
        <p class="small" i18n="@@userSurvey.question3-education-option3-examples">
          z. B. Fachhochschule, Eidg. Fachausweis, Universität
        </p>
      </div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.education === 3"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({education: 4})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.education === 4 }"
    >
      <ng-container i18n="@@userSurvey.question3-education-option4">Anderes</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.education === 4"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({education: -9})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.education === -9 }"
    >
      <ng-container i18n="@@noAnswer">Keine Antwort</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.education === -9"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
  </div>
</div>

<!-- SchoolRelation Question -->
<div class="content content-vpad margin-m" *ngIf="currentQuestion === 3">
  <div class="margin-s">
    <h2>
      <span class="h2-highlight"
        >&nbsp;<ng-container i18n="@@userSurvey.tellUsWhoYouAre"
          >Sagst du uns, wer du bist?</ng-container
        >&nbsp;</span
      >
    </h2>
    <h1 i18n="@@userSurvey.question4-schoolRelation">
      Was beschreibt deinen aktuellen Bezug zur Schule am besten?
    </h1>
  </div>
  <div class="answer-buttons">
    <button
      (click)="submitAnswer({schoolRelation: 1})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.schoolRelation === 1 }"
    >
      <div i18n="@@userSurvey.question4-schoolRelation-option1">Lehrperson an einer Schule</div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.schoolRelation === 1"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({schoolRelation: 2})"
      class="button button-answer icon-right extra-large"
      [ngClass]="{ 'selected': userSurveyAnswers.schoolRelation === 2 }"
    >
      <div i18n="@@userSurvey.question4-schoolRelation-option2">
        Elternteil von mindestens einem schulpflichtigen Kind
      </div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.schoolRelation === 2"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({schoolRelation: 3})"
      class="button button-answer icon-right extra-large"
      [ngClass]="{ 'selected': userSurveyAnswers.schoolRelation === 3 }"
    >
      <div i18n="@@userSurvey.question4-schoolRelation-option3">
        Studentin oder Student an einer Pädagogischen Hochschule
      </div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.schoolRelation === 3"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({schoolRelation: 4})"
      class="button button-answer icon-right extra-large"
      [ngClass]="{ 'selected': userSurveyAnswers.schoolRelation === 4 }"
    >
      <div i18n="@@userSurvey.question4-schoolRelation-option4">
        Dozentin oder Dozent an einer Pädagogischen Hochschule
      </div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.schoolRelation === 4"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({schoolRelation: 5})"
      class="button button-answer icon-right extra-large"
      [ngClass]="{ 'selected': userSurveyAnswers.schoolRelation === 5 }"
    >
      <div i18n="@@userSurvey.question4-schoolRelation-option5">
        Ich besuche gegenwärtig eine Schule
      </div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.schoolRelation === 5"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({schoolRelation: 6})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.schoolRelation === 6 }"
    >
      <div i18n="@@userSurvey.question4-schoolRelation-option6">Politik und Verwaltung</div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.schoolRelation === 6"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({schoolRelation: 7})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.schoolRelation === 7 }"
    >
      <div i18n="@@userSurvey.question4-schoolRelation-option7">Anderes</div>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.schoolRelation === 7"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({schoolRelation: -9})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.schoolRelation === -9 }"
    >
      <ng-container i18n="@@noAnswer">Keine Antwort</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.schoolRelation === -9"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
  </div>
</div>

<!-- PH Question -->
<div class="content content-vpad margin-m" *ngIf="currentQuestion === 4">
  <div class="margin-s">
    <h2>
      <span class="h2-highlight"
        >&nbsp;<ng-container i18n="@@userSurvey.tellUsWhoYouAre"
          >Sagst du uns, wer du bist?</ng-container
        >&nbsp;</span
      >
    </h2>
    <h1 i18n="@@userSurvey.question5-ph">
      Wenn du gegenwärtig an einer Pädagogischen Hochschule studierst, gib bitte deine PH an
    </h1>
  </div>
  <div class="answer-buttons">
    <button
      (click)="submitAnswer({ph: 1})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 1 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option1">PH Bern</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 1"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 2})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 2 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option2">HEP BEJUNE</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 2"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 3})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 3 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option3">PH Freiburg</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 3"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 4})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 4 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option4">PH Graubünden</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 4"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 5})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 5 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option5">PH FHNW</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 5"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 6})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 6 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option6">PH St. Gallen</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 6"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 7})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 7 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option7">PH Luzern</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 7"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 8})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 8 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option8">PH Schaffhausen</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 8"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 9})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 9 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option9">PH Schwyz</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 9"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 10})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 10 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option10">PH Thurgau</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 10"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 11})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 11 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option11">HEP Vaud</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 11"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 12})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 12 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option12">PH Wallis</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 12"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 13})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 13 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option13">PH Zug</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 13"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 14})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 14 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option14">PH Zürich</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 14"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 15})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 15 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option15">SUPSI</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 15"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: 16})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === 16 }"
    >
      <ng-container i18n="@@userSurvey.question5-ph-option16">Andere</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === 16"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
    <button
      (click)="submitAnswer({ph: -9})"
      class="button button-answer icon-right"
      [ngClass]="{ 'selected': userSurveyAnswers.ph === -9 }"
    >
      <ng-container i18n="@@noAnswer">Keine Antwort</ng-container>
      <mat-icon
        class="icon-small"
        *ngIf="userSurveyAnswers.ph === -9"
        svgIcon="checkmark"
      ></mat-icon>
    </button>
  </div>
</div>

<div class="sticky-navigation">
  <button
    [disabled]="!previousQuestion && previousQuestion !== 0"
    (click)="navigateToQuestion(previousQuestion)"
    class="button-nav"
  >
    <mat-icon svgIcon="chevron-up"></mat-icon>
  </button>
  <button
    [disabled]="!nextQuestion || currentAnswer < -9"
    (click)="navigateToQuestion(nextQuestion)"
    class="button-nav"
  >
    <mat-icon svgIcon="chevron-down"></mat-icon>
  </button>
</div>
