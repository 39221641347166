import { Component, Inject, LOCALE_ID } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Observable, combineLatest, of } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { Apollo } from 'apollo-angular'
import {
  Election,
  Recommendation,
  RecommendationOptions,
  Candidate,
  Matching,
  Responder,
  ValueEntry
} from '@smartvote/common'
import { AnswerService } from '@core/answer.service'
import { VoterIdService } from '@core/voter-id.service'
import { LocalStorage } from '@core/tokens'
import { GetElectionsQuery, GetRecommendationQuery, GetQuestionnaireQuery } from '@graphql_types'
import { USER_SURVEY_KEY } from '../user-survey/user-survey.page'
import { MATCHING_MODULE_CONFIG } from './matching.module'
import { MatchingModuleConfiguration } from './matching.module.configuration'
import { IframeSettings, IFRAME_SETTINGS } from '@core/iframe'
import {
  QUESTIONNAIRE_START,
  QUESTIONNAIRE_END,
  RECOMMENDATION_CREATED_AT
} from 'app/questionnaire/questionnaire.page'
import { environment } from '../../environments/environment'

const {
  CreateRecommendation,
  GetRecommendation,
  GetElections,
  GetQuestionnaire
} = require('graphql-tag/loader!./matching.page.graphql')

class FilterGroupState {
  election = ''
  district = ''
  responderType: 'Candidate' | 'Party' = 'Candidate'
}

function stateIsValid(state: FilterGroupState) {
  return state.election && state.district && state.responderType
}

function stateIsEqual(a: FilterGroupState, b: FilterGroupState) {
  return (
    a.election === b.election && a.district === b.district && a.responderType === b.responderType
  )
}

@Component({
  selector: 'svi-matching-page',
  templateUrl: 'matching.page.html',
  styleUrls: ['matching.page.scss']
})
export class MatchingPage {
  tabIndex = 0
  elections: Observable<Election[]>
  recommendation: Observable<Recommendation>
  averageRecommendation: Observable<any>
  answers: any = []
  questions: any[] = []
  showAverageSmartspider = true

  loadingRecommendation = true
  // Current states
  private _elections: Election[]
  private _recommendation: Recommendation
  private _filterGroupState: FilterGroupState = new FilterGroupState()

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private answerService: AnswerService,
    private voterIdService: VoterIdService,
    @Inject(LocalStorage) private _localStorage: Storage,
    @Inject(LOCALE_ID) public localeId: string,
    @Inject(MATCHING_MODULE_CONFIG) public readonly config: MatchingModuleConfiguration,
    @Inject(IFRAME_SETTINGS) private iframeSettings: IframeSettings
  ) {
    this.answers = this.answerService.getAnswers()
    this.recommendation = combineLatest([
      this.route.queryParams,
      this._getElections(),
      this._getQuestionnaire()
    ]).pipe(
      switchMap(([params, elections, questionnaire]) => {
        const filterGroupState: FilterGroupState = {
          election: elections[0].id,
          district: elections[0].districts[0].id,
          responderType: 'Candidate'
        }
        this.questions = questionnaire.questions
        this._elections = elections as any
        this.tabIndex = parseInt(params['tab'], 10) || 0
        const recommendationId = params['rid']
        this.loadingRecommendation = true
        if (this._doCreateRecommendation(filterGroupState)) {
          return this.createRecommendation(filterGroupState)
        } else if (this._doGetRecommendation(recommendationId)) {
          return this._getRecommendation(recommendationId, 0, -1)
        } else if (stateIsValid(filterGroupState)) {
          return of(this._recommendation)
        } else {
          this._updateQueryParams({ rid: null })
          return of(null)
        }
      }),
      tap((recommendation: any) => {
        this.loadingRecommendation = false
        if (!recommendation) {
          return
        }
        recommendation.matchings = recommendation.matchings.map((matching: Matching) => ({
          ...matching,
          ...this._getListItemLabels(matching)
        }))
        recommendation.matchings.forEach((matching) => {
          matching.responder.smartspider['options'] = { fill: '#333333' }
        })
        recommendation.voter.smartspider['options'] = { fill: '#e63923' }
        if (!this._recommendation || this._recommendation.id !== recommendation.id) {
          // Keep last state before emiting to avoid infite loop
          const districts = this._elections.filter(
            (e) => e.id === recommendation.options.electionId
          )[0].districts
          const districtGroupId = districts
            .filter((d) => d.id === recommendation.options.districtId)
            .map((d) => d.groupId)[0]
          // Input districtGroupId manually, since it is not in recommendation.options
          this._filterGroupState = this._getFilterGroupState({
            ...recommendation.options,
            districtGroupId
          })

          this._recommendation = recommendation
          this._updateQueryParams({ rid: recommendation.id })
        }
        this._localStorage.setItem('recommendationId', recommendation.id)
      })
    ) as any

    this.averageRecommendation = this.apollo
      .watchQuery<GetRecommendationQuery>({
        query: GetRecommendation,
        variables: {
          recommendationId: environment.averageAnswersRecommendationId,
          offset: 0,
          limit: -1
        },
        pollInterval: 5000
      })
      .valueChanges.pipe(
        map(({ data }) => {
          const recommendation = { ...data.recommendation }
          if (recommendation) {
            recommendation.voter.smartspider['options'] = { fill: '#333333' }
          }
          return recommendation
        })
      )
  }

  private _updateQueryParams(params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      replaceUrl: true,
      queryParamsHandling: 'merge'
    })
  }

  private _getListItemLabels(matching: Matching) {
    const candidate = matching.responder as Candidate
    return { title: candidate.firstname, description: '', matchingValue: matching.matchValue }
  }

  private _getFilterGroupState(options: RecommendationOptions) {
    return {
      election: options.electionId,
      district: options.districtId,
      districtGroup: options.districtGroupId,
      responderType: options.responderType
    }
  }

  private _getElections() {
    return this.apollo
      .query<GetElectionsQuery>({
        query: GetElections
      })
      .pipe(map(({ data }) => data.elections))
  }

  private _getQuestionnaire() {
    return this.apollo
      .query<GetQuestionnaireQuery>({
        query: GetQuestionnaire
      })
      .pipe(map(({ data }) => data.questionnaire))
  }

  private _getRecommendation(recommendationId: string, offset: number, limit: number): any {
    return this.apollo
      .query<GetRecommendationQuery>({
        query: GetRecommendation,
        variables: {
          recommendationId,
          offset,
          limit
        }
      })
      .pipe(map(({ data }) => ({ ...data.recommendation })))
  }

  private _doCreateRecommendation(filterGroupState: FilterGroupState): boolean {
    if (stateIsEqual(filterGroupState, this._filterGroupState) || !stateIsValid(filterGroupState)) {
      return false
    } else {
      return true
    }
  }

  private _doGetRecommendation(rid: string) {
    return rid && !this._recommendation
  }

  private createRecommendation(filterGroupState: FilterGroupState): Observable<Recommendation> {
    this._localStorage.setItem(RECOMMENDATION_CREATED_AT, new Date().getTime().toString())
    const answers = this.answerService
      .getAnswers()
      .map((a) => ({ questionId: a.questionId, value: a.value, weight: a.weight }))

    const surveyData = this._localStorage.getItem(USER_SURVEY_KEY) || ''
    const start = this._localStorage.getItem(QUESTIONNAIRE_START) || ''
    const end = this._localStorage.getItem(QUESTIONNAIRE_END) || ''
    const options: RecommendationOptions = {
      responderType: filterGroupState.responderType,
      electionId: filterGroupState.election,
      districtId: filterGroupState.district,
      voterId: this.voterIdService.getVoterId(),
      answers,
      surveyData,
      start,
      end
    }
    if (this.iframeSettings.enabled) {
      options.inlineFrameId = this.iframeSettings.id
    }
    return this.apollo
      .mutate<any>({
        mutation: CreateRecommendation,
        variables: {
          options
        }
      })
      .pipe(map(({ data }) => ({ ...data.createRecommendation })))
  }

  goToUserSurvey() {
    this.router.navigate(['user-survey'], { queryParams: { fwd: true } })
  }

  onMatchingSelected(matching: Matching) {
    if (matching.responder) {
      this.router.navigate(
        ['profile', matching.responder.id, (matching.responder as Candidate).district.electionId],
        { queryParamsHandling: 'preserve' }
      )
    }
  }

  resumeQuestionnaire() {
    const lastQuestionVisited = this._localStorage.getItem('lastQuestionVisited')
    if (!lastQuestionVisited) {
      // find first non-answered question
      const firstUnansweredQuestion = this.questions.find(
        (q) => !this.answers.some((a) => a.questionId === q.id)
      )
      this.router.navigate(['questionnaire', 'category', firstUnansweredQuestion.category.id], {
        queryParams: { questionId: firstUnansweredQuestion.id }
      })
    } else {
      this.router.navigateByUrl(lastQuestionVisited)
    }
  }

  setShowAverageSmartspider(value: boolean) {
    this.showAverageSmartspider = value
  }

  print() {
    window.print()
  }
}

export function getValue(responder: Responder, key: string) {
  if (!responder) {
    return null
  }
  const result = responder.values.find((entry: ValueEntry) => entry.key === key)
  return result ? result.value : null
}
