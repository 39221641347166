import { Component, OnInit } from '@angular/core'
import { TrackingService } from './core/tracking.service'
import { environment } from 'environments/environment'
import { MatIconRegistry } from '@angular/material'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'svi-root',
  template: ` <svi-layout></svi-layout> `,
  styles: ['']
})
export class AppComponent implements OnInit {
  showLayover = environment.showPrivacyLayover
  preventDemoStartUrls = ['/home', '/']
  constructor(
    private trackingService: TrackingService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon(
      'arrow-right',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/arrow-right.svg')
    )
    iconRegistry.addSvgIcon(
      'chevron-right',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/chevron-right.svg')
    )
    iconRegistry.addSvgIcon(
      'hamburger',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/hamburger.svg')
    )
    iconRegistry.addSvgIcon(
      'checkmark',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/checkmark.svg')
    )
    iconRegistry.addSvgIcon(
      'cross',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/cross.svg')
    )
    iconRegistry.addSvgIcon(
      'chevron-up',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/chevron-up.svg')
    )
    iconRegistry.addSvgIcon(
      'chevron-down',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/chevron-down.svg')
    )
    iconRegistry.addSvgIcon(
      'long-arrow-left',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/long-arrow-left.svg')
    )
    iconRegistry.addSvgIcon(
      'rectangle',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/rectangle.svg')
    )
    iconRegistry.addSvgIcon(
      'printer',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/printer.svg')
    )
    iconRegistry.addSvgIcon(
      'external-link',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/external-link.svg')
    )
  }

  ngOnInit() {
    // if (!this.showLayover) {
    //   this.startTracking()
    // }
  }

  startTracking() {
    this.trackingService.init()
    this.trackingService.trackAllPageViews()
  }
}
