<div class="content no-print">
  <svi-header [showLogo]="false"></svi-header>
</div>

<ng-container *ngIf="recommendation | async as recommendation; else loading">
  <div class="content content-vpad margin-m">
    <h1 class="no-print" i18n="@@matching.title">Dein bildungspolitisches Profil</h1>
    <h1 class="print-only print-title" i18n="@@matching.printTitle">Persönlicher Smartspider</h1>
    <img class="print-logo print-only" src="/assets/images/logo.svg" />
    <p class="no-print" i18n="@@matching.text">
      Der smartspider stellt dir deine bildungspolitischen Positionen auf acht Achsen dar. Die
      Berechnung der Positionen auf den Achsen erfolgt anhand deiner Antworten auf die Fragen zu den
      acht Einflussfaktoren der Schulreform.
    </p>
    <div class="printer-container no-print">
      <mat-icon class="printer" (click)="print()" svgIcon="printer"></mat-icon>
    </div>
    <sv-smartspider
      *ngIf="averageRecommendation | async as averageRecommendation"
      class="smartspider"
      [smartspiders]="showAverageSmartspider ? [ averageRecommendation.voter.smartspider, recommendation.voter.smartspider] : [recommendation.voter.smartspider]"
    ></sv-smartspider>
    <div class="no-print">
      <div class="legend legend-candidate">
        <mat-icon svgIcon="rectangle"></mat-icon>
        <p class="small" i18n="@@smartspiderOfAllVisitors">Smartspider aller Besucher:innen</p>
        <p
          *ngIf="showAverageSmartspider"
          (click)="setShowAverageSmartspider(false)"
          class="small legend-button"
          i18n="@@hideSmartspider"
        >
          Ausblenden
        </p>
        <p
          *ngIf="!showAverageSmartspider"
          (click)="setShowAverageSmartspider(true)"
          class="small legend-button"
          i18n="@@showSmartspider"
        >
          Einblenden
        </p>
      </div>
      <div class="legend legend-voter">
        <mat-icon svgIcon="rectangle"></mat-icon>
        <p class="small" i18n="@@mySmartspider">Mein smartspider</p>
      </div>
    </div>
  </div>

  <div
    *ngIf="answers && questions && answers.length > 0 && answers.length < questions.length"
    class="content content-dark content-vpad margin-m no-print"
  >
    <p i18n="@@resumeQuestionnaireText">
      Du hast <b>{{ answers.length}} von {{ questions.length }}</b> Fragen ausgefüllt. Um ein
      aussagekräftiges Smartspider zu erhalten, empfehlen wir dir, mit der Umfrage fortzufahren.
    </p>
    <button
      (click)="resumeQuestionnaire()"
      class="button button-primary icon-animated button-resume"
    >
      <ng-container i18n="@@resumeQuestionnaire">Umfrage fortsetzen</ng-container>
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </button>
  </div>

  <div class="content content-vpad margin-m no-print">
    <h1 i18n="@@matching.rankingTitle">Für folgende Initiativen würdest du aktuell abstimmen</h1>
    <p i18n="@@matching.rankingText">
      Kurzer Erklärtext zu den fünf Initiativen und wie sie mit den acht Kategorien verknüpft sind.
    </p>

    <div class="matching-list">
      <svi-list-item
        *ngFor="let matching of recommendation.matchings; let i = index"
        [photoUrl]="matching.responder ? matching.responder.photoUrl : null"
        [rank]="i + 1"
        [matchingValue]="matching.matchValue"
        [fullWidth]="i === 0"
        (click)="onMatchingSelected(matching)"
      ></svi-list-item>
    </div>
  </div>
  <div class="print-only">
    <div class="print-matching-list">
      <svi-print-list-item
        *ngFor="let matching of recommendation.matchings; let i = index"
        [rank]="i + 1"
        [title]="matching.responder.firstname"
        [matchingValue]="matching.matchValue"
        [smartspiders]="[matching.responder.smartspider, recommendation.voter.smartspider]"
      ></svi-print-list-item>
      <div class="print-legend">
        <div class="legend legend-candidate">
          <mat-icon svgIcon="rectangle"></mat-icon>
          <p class="small" i18n="@@matching.printSmartspiderInitiativeLabel">
            Smartspider Initiative
          </p>
        </div>
        <div class="legend legend-voter">
          <mat-icon svgIcon="rectangle"></mat-icon>
          <p class="small" i18n="@@mySmartspider">Mein smartspider</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <svi-loading></svi-loading>
</ng-template>
